import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import LoginForm from './Components/LoginForm';
import { Constant } from '../../../Helpers/constant';
import FullLoader from '../../../Common/Loader/CompleteLoader';
import { CLIENT_LOGIN, GET_CLIENT_PERMISSION_FOR_USER, GET_CLIENT_ROLES_FOR_USER, GET_USER_PROFILE } from '../../../../store/actions';

const ClientLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoginType, setIsLoginType] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Login
  const { login, loginLoading } = useSelector(state => ({
    login: state?.ClientLogin?.data,
    loginLoading: state?.ClientLogin?.loading,
  }));

  // Get User
  const { getUserProfile, getUserProfileLoading } = useSelector(state => ({
    getUserProfile: state?.GetUserProfile?.data,
    getUserProfileLoading: state?.GetUserProfile?.loading,
  }));

  // Get User Permission
  const { getUserPermission, getUserPermissionLoading } = useSelector(state => ({
    getUserPermission: state?.GetClientPermissionForUser?.data,
    getUserPermissionLoading: state?.GetClientPermissionForUser?.loading,
  }));

  console.log('getUserPermission', getUserPermission);

  useEffect(() => {
    if (login !== null && isLoginType === 1) {
      dispatch({
        type: GET_USER_PROFILE,
        payload: {
          headers: {
            token: login?.headers?.authorization,
          },
        },
      });
    } else if (login !== null && isLoginType === 2) {
      dispatch({
        type: GET_CLIENT_PERMISSION_FOR_USER,
        payload: {
          urlParams: {
            user_id: login?.data?.user?.userId,
          },
          headers: { Authorization: login?.headers?.authorization },
        },
      });
    }
  }, [login]);

  useEffect(() => {
    if (getUserPermission !== null) {
      localStorage.setItem('userType', JSON.stringify(Constant?.userTypes?.CLIENT ?? -1));
      localStorage.setItem(
        'authUser',
        JSON.stringify({
          status: 'success',
          token: login?.headers?.authorization,
          data: {
            ...login?.data?.user,
          },
          permissions: getUserPermission,
        }),
      );
      window.localStorage.setItem(
        'data',
        JSON.stringify({
          program: {
            id: 'IaQlNnHgJr',
            name: 'ADIB',
            internalName: '',
            code: 'ADIB_V2',
            logo: '',
            url: '',
            configId: 'JjOfOscNo0',
            client: {
              id: 'qeOwLFd3O9',
              name: 'ADIB',
              contractingCountry: { id: 231, name: 'United Arab Emirates' },
              isActive: true,
              clients: getUserProfile?.user?.clients,
            },
            bookingTypes: [],
            contractingCountry: { id: '', name: '' },
          },
        }),
      );
      history.push(`/client/dashboard`);
      window.location.reload();
    }
  }, [getUserPermission]);

  useEffect(() => {
    if (getUserProfile !== null) {
      localStorage.setItem('userType', JSON.stringify(Constant?.userTypes?.CLIENT ?? -1));

      if (getUserProfile?.user?.organization?.code === 'LANDMARK_GROUP') {
        localStorage.setItem(
          'authUser',
          JSON.stringify({
            status: 'success',
            token: login?.headers?.authorization,
            data: {
              ...login?.data?.user,
            },
            permissions: ['GENERATE_VOUCHER', 'VOUCHERS_REPORT', 'CLIENT_DOWNLOAD_DUMP'],
          }),
        );

        window.localStorage.setItem(
          'data',
          JSON.stringify({
            program: {
              id: 'IaQlNnHgJr',
              name: 'ALRAJHI LANDMARK',
              internalName: '',
              code: 'ALRAJHI_LANDMARK',
              logo: '',
              url: '',
              configId: 'JjOfOscNo0',
              client: {
                id: 'qeOwLFd3O9',
                name: 'ALRAJHI LANDMARK',
                contractingCountry: { id: 231, name: 'United Arab Emirates' },
                isActive: true,
                clients: getUserProfile?.user?.clients,
              },
              bookingTypes: [],
              contractingCountry: { id: '', name: '' },
            },
          }),
        );

        // history.push(`/client/voucher-generate`);
        history.push(`/client/dashboard`);
        window.location.reload();
      } else if (getUserProfile?.user?.organization?.code === 'ADIB_V2') {
        localStorage.setItem(
          'authUser',
          JSON.stringify({
            status: 'success',
            token: login?.headers?.authorization,
            data: {
              ...login?.data?.user,
            },
            permissions: [
              'ADIB_READ',
              'POST',
              'ADIB_WRITE',
              'ANNOUNCEMENT',
              'CLIENT_DASHBOARD',
              'CLIENT_CUSTOMERS',
              'CLIENT_DELAYED_BOOKINGS',
              'CLIENT_BOOKINGS_DOWNLOAD_DUMP',
            ],
          }),
        );

        window.localStorage.setItem(
          'data',
          JSON.stringify({
            program: {
              id: 'IaQlNnHgJr',
              name: 'ADIB',
              internalName: '',
              code: 'ADIB_V2',
              logo: '',
              url: '',
              configId: 'JjOfOscNo0',
              client: {
                id: 'qeOwLFd3O9',
                name: 'ADIB',
                contractingCountry: { id: 231, name: 'United Arab Emirates' },
                isActive: true,
                clients: getUserProfile?.user?.clients,
              },
              bookingTypes: [],
              contractingCountry: { id: '', name: '' },
            },
          }),
        );

        history.push(`/client/dashboard`);
        window.location.reload();
      } else if (getUserProfile?.user?.organization?.code === 'GLOBE_TOYOTA') {
        localStorage.setItem(
          'authUser',
          JSON.stringify({
            status: 'success',
            token: login?.headers?.authorization,
            data: {
              ...login?.data?.user,
            },
            permissions: [
              'REFERRAL',
              'VALIDATE_REFERRAL',
              'LEDGER_LIST',
              'VOUCHER',
              'VALIDATE_VOUCHER',
              'USER_REGISTRATION',
              'BOOKING_MANAGEMENT',
              'CUSTOMER_MANAGEMENT',
              'UNIQUE_BOOKING_V2_READ',
              'UNIQUE_BOOKING_V2_UPDATE',
              'DOWNLOAD_ACCESS',
              'DYNAMIC_COUPON_V2_READ',
            ],
          }),
        );

        window.localStorage.setItem(
          'data',
          JSON.stringify({
            program: {
              id: 'IaQlNnHgJr',
              name: 'GLOBE TOYOTA',
              internalName: '',
              code: 'GLOBE_TOYOTA',
              logo: '',
              url: '',
              configId: 'JjOfOscNo0',
              client: {
                id: 'qeOwLFd3O9',
                name: 'GLOBE TOYOTA',
                contractingCountry: { id: 231, name: 'United Arab Emirates' },
                isActive: true,
                clients: getUserProfile?.user?.clients,
              },
              bookingTypes: [],
              contractingCountry: { id: '', name: '' },
            },
          }),
        );

        history.push(`/client/dashboard`);
        window.location.reload();
      } else {
        localStorage.setItem(
          'authUser',
          JSON.stringify({
            status: 'success',
            token: login?.headers?.authorization,
            data: {
              ...login?.data?.user,
            },
            permissions: ['CLIENT_AIRPORT_TRANSFER', 'CLIENT_AIRPORT_MEET_AND_GREET', 'CLIENT_HISTORY', 'CLIENT_CONTACT', 'CLIENT_DOWNLOAD_DUMP'],
          }),
        );

        window.localStorage.setItem(
          'data',
          JSON.stringify({
            program: {
              id: 'IaQlNnHgJr',
              name: 'MASTERCARD',
              internalName: '',
              code: 'MASTERCARD_KSA',
              logo: '',
              url: '',
              configId: 'JjOfOscNo0',
              client: {
                id: 'qeOwLFd3O9',
                name: 'Mastercard',
                contractingCountry: { id: 231, name: 'United Arab Emirates' },
                isActive: true,
              },
              bookingTypes: [],
              contractingCountry: { id: '', name: '' },
            },
          }),
        );
        history.push(`/client/dashboard`);
        window.location.reload();
      }
    }
  }, [getUserProfile]);

  const onLogin = data => {
    setIsLoginType(1);
    dispatch({
      type: CLIENT_LOGIN,
      payload: {
        data: {
          ...data,
        },
      },
    });
  };

  useEffect(() => {
    if (![null, undefined, -1].includes(window.localStorage.getItem('authUser'))) {
      let redirectionRoute = `/`;
      const userType = parseInt(JSON.parse(window.localStorage.getItem('userType') ?? -1));
      if (userType === Constant?.userTypes?.ADMIN_USER) {
        redirectionRoute = `/dashboard`;
      } else if (userType === Constant?.userTypes?.CLIENT) {
        redirectionRoute = `/client/dashboard`;
      } else if (userType === Constant?.userTypes?.VENDOR) {
        redirectionRoute = `/vendor/dashboard`;
      } else {
        redirectionRoute = `/dashboard`;
      }
      history.push(`${redirectionRoute}`);
    }
  }, []);

  return (
    <>
      {(loginLoading || getUserPermissionLoading) && <FullLoader />}
      <LoginForm onSubmit={onLogin} setIsLoginType={setIsLoginType} />
    </>
  );
};

export default ClientLogin;
