import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, thriweDiscoveryUaeUrl, thriweDiscoveryUrl } from '../../../../Containers/Config/index.js';
import { GET_MEMBERSHIPS, GET_MEMBERSHIPS_SUCCESS, GET_MEMBERSHIPS_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall.js';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getMemberships({ payload }) {
  let url = ['THRIWE_APP', 'GLOBE_TOYOTA', 'HDFC_BUNDLE', 'MASTERCARD_CL'].includes(Constant.PROJECT_CODE)
    ? `${thriweDiscoveryUrl}/v1/admin/get-memberships`
    : ['HSBC_BEACH_CLUB', 'ENBD_NATIONAL_DAY'].includes(Constant.PROJECT_CODE)
    ? `${thriweDiscoveryUaeUrl}/v1/admin/get-memberships`
    : `${baseUrl}/v2/admin/user-benefit-packs`;

  let headers = ['THRIWE_APP', 'HSBC_BEACH_CLUB', 'GLOBE_TOYOTA', 'HDFC_BUNDLE', 'MASTERCARD_CL'].includes(Constant.PROJECT_CODE)
    ? {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      }
    : {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
        'X-Requested-By': 'one-admin',
      };

  try {
    const response = yield call(apiCall, {
      url: url,
      method: 'GET',
      headers: headers,
      params: {
        ...payload?.urlParam,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_MEMBERSHIPS_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(`${response?.response?.data?.message}`);
      yield put({
        type: GET_MEMBERSHIPS_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_MEMBERSHIPS_ERROR, payload: error });
  }
}

export function* watchGetMemberships() {
  yield takeEvery(GET_MEMBERSHIPS, getMemberships);
}

function* getMembershipsSaga() {
  yield all([fork(watchGetMemberships)]);
}

export default getMembershipsSaga;
