import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Flatpickr from 'react-flatpickr';
import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row, Card, ModalFooter } from 'reactstrap';
import { BLOCK_DATE, UPDATE_LOCATION } from '../../../../../../../store/application/actionType';
import moment from 'moment';
import { Error } from '../../../../../../Common';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import axios from 'axios';
import { baseUrl, customHeaders } from '../../../../../../Config';
import { Constant } from '../../../../../../Helpers/constant';

const BlockDates = ({ data, onCloseHandler, setData }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    golfCourse: null,
    fromDate: '',
    fromTime: '00:00',
    toDate: '',
    toTime: '23:59',
    remarks: '',
    block: '',
  };
  const [state, setState] = useState(initialState);
  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const { createBlockDate, createBlockDateLoading } = useSelector(state => ({
    createBlockDate: state.CreateBlockDate.data,
    createBlockDateLoading: state.CreateBlockDate.loading,
  }));

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = (flag, event) => {
    let updatedState = { ...state };
    let timeValue = event.target.value;
    switch (flag) {
      case 'fromTime':
        if (!timeValue.includes(':')) {
          timeValue += ':00';
        }
        updatedState = {
          ...state,
          fromTime: timeValue,
        };
        break;

      case 'toTime':
        if (!timeValue.includes(':')) {
          timeValue += ':00';
        }
        updatedState = {
          ...state,
          toTime: timeValue,
        };
        break;

      case 'remarks':
        updatedState = {
          ...state,
          remarks: event?.target?.value,
        };
        break;
      case 'block':
        updatedState = {
          ...state,
          block: event?.target?.value,
        };
        break;
      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  const datePickHandler = (flag, date) => {
    let updatedState = { ...state };

    switch (flag) {
      case 'fromDate':
        updatedState = {
          ...state,
          fromDate: moment(new Date(date[0])).format('Y-MM-DD'),
        };
        break;

      case 'toDate':
        updatedState = {
          ...state,
          toDate: moment(new Date(date[0])).format('Y-MM-DD'),
        };
        break;
      default:
      // do nothing
    }
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (
      data?.fromDate !== `` &&
      data?.toDate !== `` &&
      data?.golfCourse !== null &&
      data?.toTime?.trim() !== `` &&
      data?.fromTime?.trim() !== `` &&
      data?.remarks?.trim() !== `` &&
      data?.block?.trim() !== `` &&
      new Date(data?.fromDate)?.getTime() <= new Date(data?.toDate)?.getTime() &&
      new Date(`${state?.fromDate}T${state?.fromTime}:00Z`) < new Date(`${state?.toDate}T${state?.toTime}:00Z`)
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const createBlockDateHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      // alert('passed');
      dispatch({
        type: BLOCK_DATE,
        payload: {
          data: {
            startDate: state?.fromDate ?? '',
            endDate: state?.toDate,
            Remarks: state?.remarks,
            fromTime: state?.fromTime,
            toTime: state?.toTime,
            isActive: true,
            facilityId: state?.golfCourse?.value,
            isTimeDisplay: true,
            name: state?.golfCourse?.label,
            isBlockOn: state?.block === 'true',
          },
        },
      });

      onCloseHandler();
    }
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setData(null);
    setmodal_varying1(!modal_varying1);
  };

  const updateBlockDateHandler = data => {
    setState({
      ...data,
      // dateRange: [new Date(data?.startDate, )]
      remarks: data?.remarks,
    });

    onCloseHandler();
  };

  const loadGolfCourse = async search => {
    return await axios({
      method: `GET`,
      url: `${baseUrl}/admin/facilities`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        name: search,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.results?.map(data => {
            return {
              value: data.objectId,
              label: `${data.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  };

  const onSelectGolfCourse = selectedOption => {
    setState({ ...state, golfCourse: selectedOption });
  };

  useEffect(() => {
    if (createBlockDate !== null) {
      onCloseHandler();
    }
  }, [createBlockDate]);

  useEffect(() => {
    if (createBlockDateLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [createBlockDateLoading]);

  return (
    <Modal isOpen={modal_varying1} id="block-dates" size="lg">
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title">Block facility</h5>
        <Button type="button" onClick={() => tog_varying1()} className="btn-close btn-light" aria-label="Close"></Button>
      </div>
      <ModalBody>
        {/* <button onClick={() => console.log(state)}>get state</button> */}
        <Row>
          <Col xxl={12}>
            <Row>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <div className="mb-3">
                  <Label htmlFor="currency" className="form-label">
                    Facility
                    <span className="lbl_mandatory"> *</span>
                  </Label>
                  <AsyncPaginate
                    debounceTimeout={500}
                    value={state.golfCourse}
                    loadOptions={loadGolfCourse}
                    onChange={selectedOption => onSelectGolfCourse(selectedOption)}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    noOptionsMessage={() => 'No results found'}
                    cacheUniqs={[['code']]}
                    placeholder="Select..."
                    
                  />
                  {isValidationShow && state?.golfCourse === null && <Error text="Please select facility" />}
                </div>
              </Col>

              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <div className="mb-3">
                  <Label htmlFor="voucherDetailsExpiry" className="form-label">
                    From Date
                    <span className="lbl_mandatory"> *</span>
                  </Label>
                  <Flatpickr
                    className="form-control"
                    onChange={data => datePickHandler('fromDate', data)}
                    value={state.fromDate}
                    options={{
                      dateFormat: 'Y-m-d',
                      minDate: 'today',
                    }}
                  />
                  {isValidationShow && state?.fromDate === `` && <Error text="Please select from date" />}
                  {/* {isValidationShow && state?.fromDate !== `` && new Date(state?.fromDate)?.getTime() >= new Date(state?.toDate)?.getTime() && (
                    <Error text="From date is invalid" />
                  )} */}
                </div>
              </Col>

              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <div className="mb-3">
                  <Label htmlFor="voucherDetailsExpiry" className="form-label">
                    To Date
                    <span className="lbl_mandatory"> *</span>
                  </Label>
                  <Flatpickr
                    className="form-control"
                    onChange={data => datePickHandler('toDate', data)}
                    value={state?.toDate}
                    options={{
                      dateFormat: 'Y-m-d',
                      minDate: 'today',
                    }}
                  />
                  {isValidationShow && state?.toDate === `` && <Error text="Please select to date" />}
                  {isValidationShow && state?.toDate !== `` && new Date(state?.fromDate)?.getTime() > new Date(state?.toDate)?.getTime() && (
                    <Error text="To date should be after or equal to from date" />
                  )}
                </div>
              </Col>

              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <div className="mb-3">
                  <Label htmlFor="fromTime" className="form-label">
                    From Time
                    <span className="lbl_mandatory"> *</span>
                  </Label>
                  <Input
                    id="fromTime"
                    name="fromTime"
                    type="time"
                    className="form-control"
                    onChange={e => inputChangeHandler('fromTime', e)}
                    value={state?.fromTime}
                    autoComplete="off"
                    disabled={false}
                  />
                  {isValidationShow && state?.fromTime === `` && <Error text="Please select from time" />}
                </div>
              </Col>

              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <div className="mb-3">
                  <Label htmlFor="toTime" className="form-label">
                    To Time
                    <span className="lbl_mandatory"> *</span>
                  </Label>
                  <Input
                    id="toTime"
                    name="toTime"
                    type="time"
                    className="form-control"
                    onChange={e => inputChangeHandler('toTime', e)}
                    value={state?.toTime}
                    autoComplete="off"
                    disabled={false}
                  />
                  {isValidationShow && state?.toTime === '' && <Error text="Please enter to time" />}
                  {isValidationShow &&
                    state?.fromDate !== '' &&
                    state?.toDate !== '' &&
                    new Date(state?.fromDate)?.getTime() <= new Date(state?.toDate)?.getTime() &&
                    new Date(`${state?.fromDate}T${state?.fromTime}:00Z`) > new Date(`${state?.toDate}T${state?.toTime}:00Z`) && (
                      <Error text="To time should be after from time" />
                    )}
                </div>
              </Col>
              <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                <div className="mb-3">
                  <Label htmlFor="remarks" className="form-label">
                    Block / Unblock
                    <span className="lbl_mandatory"> *</span>
                  </Label>
                  <select
                    id="block"
                    placeholder={'Please Select'}
                    onChange={e => inputChangeHandler('block', e)}
                    name="block"
                    value={state?.block}
                    className="form-control"
                  >
                    <option value={''}>Please Select</option>
                    <option value={false}>Unblock </option>
                    <option value={true}>Block </option>
                  </select>

                  {isValidationShow && state?.block?.trim() === '' && <Error text="Please select an option" />}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="mb-3">
                  <Label htmlFor="remarks" className="form-label">
                    Remarks
                    <span className="lbl_mandatory"> *</span>
                  </Label>
                  <Input
                    id="remarks"
                    name="remarks"
                    type="textarea"
                    className="form-control"
                    onChange={e => inputChangeHandler('remarks', e)}
                    value={state?.remarks}
                    autoComplete="off"
                    rows={4}
                    disabled={false}
                  />
                  {isValidationShow && state?.remarks?.trim() === '' && <Error text="Please enter remarks" />}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <div className="d-flex mt-4" style={{ justifyContent: 'center', margin: 'auto', gap: '10px' }}>
          <button className="btn btn-primary" onClick={data ? updateBlockDateHandler : createBlockDateHandler}>
            {data ? 'Update' : 'Submit'}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default BlockDates;
