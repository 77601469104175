import axios from 'axios';
import { api } from '../config';
import { Constant } from '../Containers/Helpers/constant';

// default
axios.defaults.masterCardBaseURL = api.API_URL;
// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';

// content type
const token = JSON.parse(localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).token : null;
const userType = localStorage.getItem('userType');

axios.interceptors.response.use(
  function (response) {
    return response;
  },

  function (response) {
    // return response;
    if (Constant.userTypes.CLIENT == userType && Constant.PROJECT_CODE === 'GLOBE_TOYOTA') {
      if ([401]?.includes(Number(response?.response?.data?.statusCode))) {
        localStorage.clear();
        window.location.href = '/client/login';
      }
      // return;
    } else if ([403]?.includes(response?.response?.status)) {
      if (userType == Constant.userTypes.ADMIN_USER) {
        localStorage.clear();
        window.location.href = '/login';
        // return;
        // window.location.reload();
      } else if (userType === Constant.userTypes.CLIENT) {
        localStorage.clear();
        window.location.href = '/client/login';
        // return;
      } else {
        localStorage.clear();
        window.location.href = '/vendor/login';
        // return;
      }
    } else {
      return response;
    }
  },
);

// if (token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

// intercepting to capture errors
// axios.interceptors.response.use(
//   function (response) {
//     return response.data ? response.data : response
//   },
//   function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     let message
//     switch (error.status) {
//       case 500:
//         message = 'Internal Server Error'
//         break
//       case 401:
//         message = 'Invalid credentials'
//         break
//       case 404:
//         message = 'Sorry! the data you are looking for could not be found'
//         break
//       default:
//         message = error.message || error
//     }
//     return Promise.reject(message)
//   },
// )
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = token => {
  // axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : '';
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}
const getLoggedinUser = () => {
  const user = localStorage.getItem('authUser');
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };
