import { useEffect, useState } from 'react';

import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Badge, Card, CardBody, CardFooter, Col, Row } from 'reactstrap';

import { PreviewCardHeader, TablePagination } from '../../../../Common';
import { Constant } from '../../../../Helpers/constant';

const Table = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No.',
          width: '70px',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
        },
        {
          name: 'File Name',
          wrap: true,
          minWidth: '200px',
          selector: row => row?.filename,
        },
        {
          name: 'Generated At',
          width: '180px',
          wrap: true,
          selector: row => row?.createdAt,
        },
        {
          name: 'Status',
          width: '160px',
          wrap: true,
          selector: row => checkDownloadStatusHandler(row?.status),
        },
        {
          name: 'Action',
          width: '150px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column w-100">
              {row?.originalData?.status === 2 && (
                <span
                  className="btn btn-sm btn-primary mb-1 w-100"
                  onClick={() => {
                    actionBtnHandler(`DOWNLOAD`, row?.originalData);
                  }}
                >
                  <div className="">
                    <i className="ri-download-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>Download
                  </div>
                </span>
              )}
            </div>
          ),
        },
      ];
      setColumns(fetchColumns);

      const data = props?.data?.map((download, index) => ({
        serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
        name: download?.user?.name,
        filename: download?.filename,
        status: download?.status,
        createdAt: moment(new Date(`${download?.createdAt}`)).format('DD-MM-YYYY, h:mm A'),
        originalData: download,
      }));
      setData(data);
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `DOWNLOAD`) {
      props.actionHandler({
        downloadDetails: data,
        actionType: flag,
      });
    }
  };

  /* ---------------------------- CHECK DOWNLOAD STATUS HANDLER ---------------------------- */
  const checkDownloadStatusHandler = status => {
    switch (status) {
      case 1:
        return <Badge color="warning"> {`In-process`} </Badge>;

      case 2:
        return <Badge color="success"> {`Ready`} </Badge>;

      case 3:
        return <Badge color="danger"> {`Failed`} </Badge>;

      default:
      // Need not to do anything
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Downloads" refreshBtn={props?.data?.length > 0 ? true : false} refreshHandler={props?.refreshHandler} />

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                      padding: '8px',
                    },
                  },
                }}
              />
            </CardBody>
            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col className="d-flex justify-content-between align-items-center">
                    <span>
                      Showing <b>{props?.data?.length}</b> of <b>{props?.totalData}</b> records
                    </span>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
